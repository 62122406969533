* {
  box-sizing: border-box;
}

h1 {
  letter-spacing: 2px;
  text-align: center;
  /* text-transform: uppercase; */
  font-family: 'Roboto';
  color: white;
  margin-bottom: 0px;
}

.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-family: 'Roboto';
}

.countdown-item {
  color: white;
  font-size: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 50px;
  margin: 10px;
  padding-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
  font-family: 'Roboto';
}

.countdown-item span {
  color: white;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Roboto';
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  font-family: 'Roboto';
}

footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
  font-family: 'Roboto';
}

footer p {
  margin: 10px 0;
  font-family: 'Roboto';
}

footer i {
  color: red;
}

footer a {
  color: #3c97bf;
  text-decoration: none;
}
